import React from 'react';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Axios from 'axios';

const PopSwal = withReactContent(Swal)

function Loading() {
  return <div>Loading...</div>;
}

export function showLoading(msg){
  PopSwal.fire({ 
    title: msg,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      PopSwal.showLoading();
    }
  });
}

export function loadSuccess(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'success',
    timer: 2000,
    showConfirmButton: true
  });
}

export function loadImage(img){
  PopSwal.fire({ 
    title: "View Receipt",
    html : img,
    showConfirmButton: true
  });
}

export function validatePwd(pwd){
  var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,12}$/;
  if(pwd.match(passw)) {
    return true;
  }else{
    return false;
  } 
}

export function loadSuccessIcon(msg,icon){
  PopSwal.fire({ 
    title: msg,
    iconHtml: icon,
    customClass: {
      icon: 'no-border'
    },
    timer: 4000,
    showConfirmButton: false
  }).then((result) => {
      window.location.reload()
  })
}

export function loadError(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'error',
    timer: 3000,
    showConfirmButton: true
  })
}

export function loadErrorRetry(msg,callback){
  PopSwal.fire({ 
    title: msg,
    icon: 'error',
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: true
  }).then((result) => {
    if (typeof callback === "function") {
      callback()
    }else{
      window.location.reload()
    }
  })
}

export function updateloginStatus(status = false,expires=1){
  Cookies.set("islogged", status, {path: "/", expires : expires, secure : true});
}


export function setToken(token,expires=1){
  Cookies.set("obulus", token, {path: "/", expires : expires, secure : true});
}


export function setOutlet(outlet,expires=1){
  Cookies.set("outlet", outlet, {path: "/", expires : expires, secure : true});
}

export function getOutlet(){
  return Cookies.get("outlet");
}

export function getToken(){
  return Cookies.get("obulus");
}

export function isLoggedIn(){ 
  return Cookies.get("islogged"); 
}

export function destroySession(){
  Cookies.remove("obulus",{ path: '/' , secure : true});
  Cookies.remove("islogged",{ path: '/', secure : true });
  Cookies.remove("argos",{ path: '/' , secure : true});
  Cookies.remove("outlet",{ path: '/' , secure : true});
  Axios.post(`${APIHOST}logout`, {})
  .then(function(res){
  }).catch(function(er){
  })
}

export const APIHOST = "https://llapi.roadshowcampaign.com/api/";

