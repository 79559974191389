import React, { Component } from 'react';
import {Images} from '../../utils/images';
import {getOutlet} from '../../utils/gaia';

const Header = (props) => {
    return (
        <>
        <header className="header_section">
            {(props.logged && props.nav == "report") && <a href='/report'><img src={Images.report} className="icon" /></a>}
            {(props.logged && props.nav == "scan") && <a href='/app'><img src={Images.scan} className="icon" /></a>}
            <div> <a href="/"><img  className="logo" src={Images.logo} alt="mainlogo" /></a> </div>
            {props.logged && <p className='station'>Station ID : {getOutlet()}</p>}
        </header>
      </>
    );
}
export default Header;