import React, { useState,useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import CheckRequests from './interceptor'
import {getToken,destroySession,isLoggedIn} from './utils/gaia';
import Axios from 'axios';
import {useIdleTimer} from 'react-idle-timer'

//v3.2
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Font Awesome Icons Set
import '@fortawesome/fontawesome-free/css/all.css';

// Import Main styles for this application
import './assets/css/fonts.css'
import './assets/css/style.css'

//import Main scaffolding
import Login from './views/Pages/Login';
import Home from './views/Scaffolds/Home'
import Report from './views/Scaffolds/Report';

const token = getToken()
if (token) {
  Axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}

Axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;  
}, function (error) {       
  if(typeof(error.response) !== "undefined"){
      switch (error.response.status) {
          case 503 :
            
              break
          case 401 :
              destroySession(); 
              window.location.replace("/login")
              //props.history.push('/login') //we will redirect user into 503 page 
              break
          default :
              break
      }
  }
  // Do something with response error
  return Promise.reject(error);
});

const App = () => {
  
  const [timeout, setTimeout] = useState(1000 * 60 * 20);
  const idleTimerRef = useRef(null);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(isLoggedIn());
  const _onAction = (e) => {
    console.log("action")
    setIsTimedOut(false)
  }
 
  const _onActive = (e) => {
    console.log("active")
    setIsTimedOut(false)
  }
 
  const _onIdle = (e) => {
    setIsTimedOut(true)
    if (isTimedOut) {
      if(userLoggedIn){
        destroySession();
      }     
    } else {

    }
  }

  const IdleTimer = useIdleTimer({
    crossTab: true,
    ref : idleTimerRef,
    element : document,
    onActive : _onActive,
    onIdle : _onIdle,
    onAction : _onAction,
    debounce : 250,
    timeout : timeout,
  })


  return (
    <div className="container">
    <BrowserRouter>   
      <Routes>
        <Route exact path="/app/*" name="Dashboard" element={<Home />} />
        <Route exact path="/report" name="Report" element={<Report />} />
        <Route exact path="/login" name="Login" element={<Login />} />
        <Route exact path="/" name="Login" element={<Login />} />
        
      </Routes> 
    </BrowserRouter>
    </div>
  );
}

export default App;
