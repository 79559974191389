import React, { useState } from 'react';
import {Images} from '../../utils/images';
import Axios from 'axios';
import {APIHOST,loadSuccess, loadError} from '../../utils/gaia';

import { Modal, ModalHeader, ModalBody, ModalFooter,Button } from 'reactstrap';
import { QrReader } from 'react-qr-reader';

const ScanModal = (props) => {
    const [data, setData] = useState('');
    const [canScan, setCanscan] = useState(true);
    const [loading, setLoading] = useState(false);

    const checkQR = (code) =>{
        setLoading(true)
        Axios.post(`${APIHOST}member/checkMember`, {
          payload: code,
        }).then(function(res){
            setLoading(false)
            let {data} = res;
            props.setMid(data.mid);
            props.toggle();
        }).catch(function(er){
          setLoading(false)
          setCanscan(true)
          if(typeof(er.response) !== "undefined"){
            if(er.response.status == 404 || er.response.status == 500){
              loadError(er.response.data.error)
            }else{
              loadError("A network error occured, please try again later.")
            }
          }else{
            loadError(er.message)
          }
          
        })
      }

    const scanResult = (result,error) =>{
        
        if (result && canScan) {
            setData(result?.text);
            setCanscan(false);
            checkQR(result?.text);
        }

        if (!!error && canScan) {
            console.info(error);
        }
    }
    return (
        <Modal centered isOpen={props.modal} toggle={props.toggle} className={props.className}>
          <ModalHeader toggle={props.toggle}>Scan Member QR Code</ModalHeader>
          <ModalBody>
            {canScan &&<QrReader scanDelay={1000} constraints={{ facingMode: 'environment' }} onResult={scanResult} style={{ width: '100%' }} />}
            {loading &&<p className='text-center'><i className='fa fa-spinner fa-spin fa-2x'></i> <br />Checking QR... Please wait...</p>}
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={props.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
    );
}
export default ScanModal;