import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST,updateloginStatus,setOutlet,setToken,isLoggedIn, loadSuccess, loadError} from '../../utils/gaia';
import Buttontb from '../Components/Button'
import Header from '../Components/Header'

import { Form, FormGroup, Label, Input } from 'reactstrap';
import { Images } from '../../utils/images';
 
const Login = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    if(isLoggedIn()){
      navigate(`/app`)
    }
  },[])

  const handleSubmit = (evt) =>{
    evt.preventDefault();
    if(username === "" || password === ""){
      loadError("Please enter username and password")
      return;
    }
    setLoading(true)
    Axios.post(`${APIHOST}auth/login`, {
      email: username,
      password: password,
      type: 1
    }).then(function(res){
        let {data} = res;
        updateloginStatus(true);
        setToken(data.access_token);
        setOutlet(data.outlet);
        setLoading(false)
        loadSuccess("Welcome Back")
        setTimeout(()=>{  window.location.replace(`/app`)},1500)
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

    return (
        <>
            <Header logged={false} />
            <div id="login">
                <div className="inner">
                    <h1>Portal Login</h1>
                    <div className="frm-box">
                        <Form autoComplete="off" onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label for="username">Email Address</Label>
                                <Input className="text-field" type="text" id="username" name="username" placeholder='Enter your Email Address' onChange={event => setUsername(event.target.value)} required />
                            </FormGroup>
                            <FormGroup  className="mb-4">
                                <Label for="username">PASSWORD</Label>
                                <Input className="text-field" type="password" id="password" name="password" placeholder='Enter your password' onChange={event => setPassword(event.target.value)} required />
                            </FormGroup>
                            <Buttontb loader={loading} text={"LOG IN"} classList="tmpl-main-btn" />
                        </Form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
